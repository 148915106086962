studenStaffConfig = {};

function setApiUrlsForStudentStaff(apiData){
	studenStaffConfig = apiData;
}


function getChatbotIdForStudentBot() {
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			
		},
		url: studenStaffConfig.getChatbotId,
		data: {
			chatbot_name: 'student'
		},
		success: function(response) {
			studenStaffConfig.chatBotId = response.chatbot_id
		}
	});
}

function checkSASCookiesForStudentAzureChatbot() {
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.sasCookies,
		success: function(response) {
			localStorage.setItem('sas_token', response.sas_token);
		}
	});
} 

function SasTokenForStudentAzureChatbot(){
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.sasToken,
		success: function(response) {
			localStorage.setItem('sas_token', response.sas_token);
		}
	});
}

function refreshChatStudentAzureChatbot(e, id){
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.refreshChat,
		data: {
			chatbot_id: "8647bb53-a054-4406-ba28-442f1848807f",
			session_id: localStorage.getItem('session_uuid'),
		},
		success: function (response) {
			
			var bot_msg = response.text_response;
			
			setTimeout(function () {
				$('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled').empty();

				// Create and append a new list item
				var welcome_message = $(`<li class="media received">
											<div class="avatar avatar-xs avatar-soft-primary avatar-icon avatar-rounded">
												<div class="avatar avatar-xs avatar-rounded">
													<img src="https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media" alt="user" class="avatar-img">
												</div>
											</div>
											<div class="media-body">
												<div class="msg-box">
													<div>
														<p>Hello! How can I assist you today?</p>
													</div>
												</div>
												
											</div>
										</li>`);
				$('.hk-chatbot-popup ul.list-unstyled').append(welcome_message);
				var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
				chatBox.scrollTop = chatBox.scrollHeight;
			}, 0);
		}
	});
		
}

async function inputMsgChatPopupStudentAzureChatbot(e, id){
	if ((e.which == 13)&&(!$(id).val().length == 0)) {
		const messageInputField = document.getElementById("input_msg_chat_popup");
		message = messageInputField.value.trim();
		$('<li class="media sent"><div class="media-body"><div class="msg-box"><div><p>' + $(id).val() + '</p> </div></div></div></li>').appendTo(".hk-chatbot-popup  ul.list-unstyled");
		$(id).val('');
		
		$(`<li class="media received typing-wrap">
			<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src='https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media'
					style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
			<div class="media-body">
				<div class="msg-box">
					<div>
						<p>Looking for <strong>${message}</strong></p>
					</div>
				</div>
				<div class="msg-box">
					<div>
						<div class="typing">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</div>
		</li>`).appendTo('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled');
		
		var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
		chatBox.scrollTop = chatBox.scrollHeight;
		
		// Get the current date and time
		var now = new Date();
		var formatted_date = now.toISOString().slice(0, 19).replace(/-/g, "_").replace("T", "_").replace(/:/g, "_");
		const convo_id = localStorage.getItem('session_uuid') + "_" + formatted_date;
		
		const response = await fetch(studenStaffConfig.searchApiRequest, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				chatbot_id: studenStaffConfig.chatBotId,
				session_id: localStorage.getItem('session_uuid'),
				message: message,
				userEmail: sessionStorage.getItem('userEmail'),
				origin: "Student",
				convo_id: convo_id
			}),
		});

		const decoder = new TextDecoder();
		const reader = response.body.getReader();

		let text_response = ""
		var rephrased_query = message;

		while(true) {
			const {done, value} = await reader.read();
			if (done) {
				let inputText = text_response;
				if(inputText.includes("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$")){
					inputText = inputText.replace("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$","")
				}
				console.log("O/p Response for user : check--> ",inputText);
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `https://genaicopilotmmpdevblob.blob.core.windows.net/student-copilot-dev-8647bb53-a054-4406-ba28-442f1848807f/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				let citationText = "";
				const pdfCitations = [];
				const websiteCitations = [];
				const unverified = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						const citationLink = citation.hyperlink;
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				const pdfCitationText = pdfCitations.length > 0 ? `<div class="msg-box"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden Owned Private Data Source</p>${pdfCitations.join("")}</span></div>` : "";
				const websiteCitationText = websiteCitations.length > 0 ? `<div id="student_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				const unverifiedText = unverified.length > 0 ? `<div id="student_unverified_citation_msg_box" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";

				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Incorrect Response', 'Data Inaccuracy', 'Time Delayed Response Received', 'Partially Correct Response']
					$(`	
					<li class="media received">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src='https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media'
								style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generated results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div>
									<p id="${convo_id}_answer">${processedText}</p>
								</div>
								<div class="msg-action" style="background:transparent;">
									<p id="${convo_id}_action_update" value='' hidden></p>
									<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
									<div class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item">
											<label for="convo_feedback">Would you like to share a feedback?</label>
											<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
											<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
										</a>
									</div>
									<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary" data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
									<div class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item">
											<label for="convo_feedback">Would you like to share a feedback?</label>
											<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text"></textarea>
											<div class="form-check">
											<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1" type="checkbox" value="${checkFeedbackOptions[0]}">
											<label class="form-check-label" id="label1" for="checkbox1">
											${checkFeedbackOptions[0]}
											</label>
										  	</div>
										  	<div class="form-check">
											<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2" type="checkbox" value="${checkFeedbackOptions[1]}">
											<label class="form-check-label" id="label2" for="checkbox2">
											${checkFeedbackOptions[1]}
											</label>
										  	</div>
										  	<div class="form-check">
											<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3" type="checkbox" value="${checkFeedbackOptions[2]}">
											<label class="form-check-label" id="label3" for="checkbox3">
											${checkFeedbackOptions[2]}
											</label>
										  	</div>
										  	<div class="form-check">
											<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox4" type="checkbox" value="${checkFeedbackOptions[3]}">
											<label class="form-check-label" id="label4" for="checkbox4">
											${checkFeedbackOptions[3]}
											</label>
										  	</div>
											<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
										</a>
									</div>
								</div>
							</div>
							<div class="mt-2">
							<button id="student_citation_btn" data-convo_id='${convo_id}' style="border-radius: 20px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; width: 30%; color:blue; margin: 2px 10px 2px 2px; margin-button: 15px; border: none; height: 50px"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</button>
							  <button id="copy_response_btn" data-convo_id='${convo_id}' style="border-radius: 20px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;; width: 30%; color:blue; margin: 2px 2px 2px 10px; border: none; height: 50px"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</button>
								${pdfCitationText}
								${websiteCitationText}
								${unverifiedText}
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");
					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

				}, 0);

				break
			} 
			else {
				// text_response += decoder.decode(value);
				let inputText = text_response;
				var rq_regex = /\$SRQ\$(.*?)\$ERQ\$/;
				var rq_match = decoder.decode(value).match(rq_regex);
				// console.log(rq_match);
				if (rq_match && rq_match.length > 1) {
					rephrased_query = rq_match[1];
					inputText = text_response;
				} else {
					text_response += decoder.decode(value);
					inputText = text_response;
				}

				var regex = /\$FINAL_CONTEXT\$(.*)/;
				// Extracting the text after $FINAL_CONTEXT$ using the regular expression
				var match = decoder.decode(value).match(regex);

				// Check if there is a match and extract the text
				if (match && match.length > 1) {
					var extractedText = match[1].trim();
					text_response = extractedText;
					inputText = text_response;
				} else {
					console.log("Text after $FINAL_CONTEXT$ not found!");
				}
				
				//console.log(rephrased_query);
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `https://genaicopilotmmpdevblob.blob.core.windows.net/student-copilot-dev-8647bb53-a054-4406-ba28-442f1848807f/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				let citationText = "";
				const pdfCitations = [];
				const websiteCitations = [];
				const unverified = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						const citationLink = citation.hyperlink;
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				const pdfCitationText = pdfCitations.length > 0 ? `<div id="" class="msg-box"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden Owned Private Data Source</p>${pdfCitations.join("")}</span></div>` : "";
				const websiteCitationText = websiteCitations.length > 0 ? `<div class="msg-box"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				const unverifiedText = unverified.length > 0 ? `<div class="msg-box"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";

				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					$(`	
					<li class="media received streambubble">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src='https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2Fimages%2FChatbot.svg?alt=media'
								style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generating results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div>
									<p id="${convo_id}_answer">${processedText}</p>
									<div class="typing" style="justify-content: right;padding-top: 10px;">
										<div class="dot"></div>
										<div class="dot"></div>
										<div class="dot"></div>
									</div>
								</div>
								<div class="msg-action" style="background:transparent;">
									<p id="${convo_id}_action_update" value='' hidden></p>
									<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
									<div class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item">
											<label for="convo_feedback">Would you like to share a feedback?</label>
											<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
											<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
										</a>
									</div>
									<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary" data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
									<div class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item">
											<label for="convo_feedback">Would you like to share a feedback?</label>
											<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text"></textarea>
											<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
										</a>
									</div>
								</div>
							</div>
							<div class="mt-2">
								${pdfCitationText}
								${websiteCitationText}
								${unverifiedText}
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

				}, 0);
			}
		}
		

	} 
	return;
}

function likeConvoStudentAzureChatbot(e, id){
	var convoID = id.getAttribute('data-convo_id');
	document.getElementById(convoID+"_action_update").value = "like";
	console.log(document.getElementById(convoID+"_query"));
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.convoAction,
		data: {
			chatbot_id: studenStaffConfig.chatBotId,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute('data-convo_id'),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute('data-convo_action')
		},
	});
}

function dislikeConvoStudentAzureChatbot(e, id){
	var convoID = id.getAttribute('data-convo_id');
	document.getElementById(convoID+"_action_update").value = "dislike";
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.chatBotId,
		data: {
			chatbot_id: studenStaffConfig.chatBotId,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute("data-convo_id"),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute("data-convo_action")
		}
	});
}

function sendDislikeFeedbackStudentAzureChatbot(e, id){
	var checkedLabels = []
	for(let i=1;i<=4;i++){
		var checkbox = document.getElementById(`checkbox${i}`);
		if(checkbox.checked){
			checkedLabels.push(checkbox.value);
		}
	}
	var convoId = id.getAttribute("data-convo_id");
	var value = document.getElementById(convoId+"_convo_feedback_dislike").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var checkedLabels = checkedLabels.toString();
	var action = document.getElementById(convoId+"_action_update").value;
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: studenStaffConfig.convoFeedback,
			data: {
				chatbot_id: studenStaffConfig.chatBotId,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				feedback_category:checkedLabels,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);
	return;
}

function sendLikeFeedbackStudentAzureChatbot(e, id){
	var convoId = id.getAttribute("data-convo_id");
	var value = document.getElementById(convoId+"_convo_feedback_like").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var action = document.getElementById(convoId+"_action_update").value;
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: studenStaffConfig.convoFeedback,
			data: {
				chatbot_id: studenStaffConfig.chatBotId,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);
	return;
}


$(document).on('click', "#student_citation_btn", function (e) {
	var convoId = $(this).data('convo_id');
	var citation_id = "student_citation_msg_box_" + convoId;
	var citationMsgBox = document.getElementById(citation_id);
	document.getElementById(citation_id).style.display =  (citationMsgBox.style.display == 'block' ) ? 'none' :  'block';
	var unverified_citation_id = "student_unverified_citation_msg_box";
	var unverifiedCitationMsgBox = document.getElementById(unverified_citation_id);
	document.getElementById(unverified_citation_id).style.display =  (unverifiedCitationMsgBox.style.display == 'block' ) ? 'none' :  'block';
  });

$(document).on('click', "#copy_response_btn", function (e) {
	var convoId = $(this).data('convo_id');
	var responseText = document.getElementById(convoId+"_answer").textContent;
	var tempTextArea = document.createElement('textarea');
	tempTextArea.value = responseText;
	document.body.appendChild(tempTextArea);
	tempTextArea.select();
	document.execCommand('copy');
	document.body.removeChild(tempTextArea);
});
